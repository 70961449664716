import { useState } from "react";

const BlogCard = ({ title, blogPosts, date, imageUrl }) => {
  const [expanded, setExpanded] = useState(false);

  const expandedHandler = () => {
    setExpanded((prev) => !prev);
  };

  const truncateString = (str, maxLength) => {
    if (str.length > maxLength) {
      return str.slice(0, maxLength) + "...";
    } else {
      return str;
    }
  };

  return (
    <div className="bg-white shadow-md rounded-lg overflow-hidden mb-4 p-4 h-full xl:w-72 lg:w-72 md:w-84 sm:w-72 xs:w-72">
      {imageUrl && (
        <img
          className="w-full h-36 object-cover"
          src={`${imageUrl}`}
          alt={title}
        />
      )}
      <div className="p-4">
        <h5 className="text-md font-semibold mb-2">{title}</h5>
        <p className="text-gray-600 mb-4">
          {new Date(date).toLocaleDateString()}
        </p>
        <p className="text-gray-800">
          {expanded ? "" : truncateString(blogPosts, 25)}
        </p>
        {expanded && (
          <div className="h-96 overflow-auto">
            <p className="text-gray-800">{blogPosts}</p>
          </div>
        )}
      </div>
      <div className="flex items-center justify-between px-4 py-2 border-t">
        <button
          onClick={expandedHandler}
          className="text-blue-500 hover:text-blue-700 transition-colors"
        >
          {expanded ? "Read Less" : "Read More"}
        </button>
      </div>
    </div>
  );
};

export default BlogCard;
