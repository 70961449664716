const initialState = {
  home: {
    banner: {},
    content: {},
    images: [],
  },
  about: {
    banner: {},
  },
  resources: {
    banner: {},
    content: {},
    files: [],
  },
  blog: {
    banner: {},
    content: {},
    files: [],
  },
  voter: {
    banner: {},
    content: {},
    files: [],
  },
  getInvolved: {
    banner: {},
    content: {},
    images: [],
  },
  contact: {
    banner: {},
  },
  partners: {
    banner: {},
    content: {},
    files: [],
  },
};

export default initialState;
