import styles from "../../Utility/styles";

const UpdateInfoField = ({
  handleSubmitInfo,
  handleInfo,
  _type,
  isUploaded,
  title,
  hasData,
}) => (
  <div style={{ ...styles.updateFieldContainer(_type) }}>
    <form onSubmit={handleSubmitInfo} style={styles.updateFieldForm(_type)}>
      <h3
        style={{ ...styles.updateFieldTitle(), ...styles.fontTextOne(hasData) }}
      >
        {`Update ${title} info`}
      </h3>

      <input
        onChange={handleInfo}
        type="text"
        name="_name"
        placeholder={_type === "rep" ? "Name" : _type === "partners" ? "Sponser Name" : "Button Text"}
        style={{
          marginRight: _type === "blog" ? "16%" : null,
          width: _type === "blog" ? "32%" : null,
          marginLeft: "5%",
        }}
      />

      {["rep", "partners"].includes(_type) && (
        <>
          <input
            onChange={handleInfo}
            type="email"
            name="email"
            placeholder="Email"
            style={{
              marginLeft: "2%",
              width: _type === "partners" ? "10%" : null,
            }}
          />
          <input
            onChange={handleInfo}
            type="text"
            name="title"
            placeholder={"Card Title"}
            style={{
              marginLeft: "2%",
              width: _type === "partners" ? "10%" : null,
            }}
          />
          <input
            onChange={handleInfo}
            type="text"
            name="url"
            placeholder="Website"
            style={{
              marginLeft: "2%",
              width: _type === "partners" ? "10%" : null,
            }}
          />
          {_type === "partners" && (
            <input
              onChange={handleInfo}
              type="text"
              name="phone"
              placeholder="Phone Number"
              style={{
                marginLeft: "2%",
                width: _type === "partners" ? "10%" : null,
              }}
            />
          )}
        </>
      )}

      {["link"].includes(_type) && (
        <>
          <input
            onChange={handleInfo}
            type="text"
            name="title"
            placeholder="Card Title"
            style={{ marginLeft: "2%" }}
          />
          <input
            onChange={handleInfo}
            type="text"
            name="url"
            placeholder="Website"
          />
        </>
      )}

      {["rep", "link", "partners"].includes(_type) && (
        <button
          style={{ ...styles.updateFieldUpload(isUploaded), width: "8%" }}
        >
          Update
        </button>
      )}
    </form>
  </div>
);

export default UpdateInfoField;
