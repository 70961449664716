import styles from "../../Utility/styles";
import { Outlet } from "react-router-dom";
import AdminSignIn from "../Container/AdminSignIn";
import Welcome from "../Presentational/Welcome";
import NavBar from "../Presentational/AdminNavBar";

const AdminPanel = ({
  signIn,
  setSignIn,
  handleAdminToken,
  getAdminLinks,
  shouldRenderOutlet,
  admin,
  handleSignOut,
}) => {
  return (
    <>
      {signIn ? (
        <AdminSignIn
          setSignIn={setSignIn}
          handleAdminToken={handleAdminToken}
        />
      ) : (
        <>
          <NavBar
            handleSignOut={handleSignOut}
            getLinks={getAdminLinks}
            admin
          />
          <div id="adminMain" style={styles.adminMainContainer()}>
            {shouldRenderOutlet ? <Outlet /> : <Welcome admin={admin} />}
          </div>
        </>
      )}
    </>
  );
};

export default AdminPanel;
