import axios from "axios";

const baseURL = "https://api.cochisecountyconservatives.com";

const createRequest = ({ method, url, headers = {}, data = {} }) =>
  axios({
    method,
    url: `${baseURL}${url}`,
    headers,
    data,
  });

const Requests = {
  upload_info: (payload, type, token) =>
    createRequest({
      method: "POST",
      url: `/upload_info/${type}`,
      headers: { token },
      data: payload,
    }),
  upload_files: (payload, type, token) =>
    createRequest({
      method: "POST",
      url: `/upload_files/${type}`,
      headers: { token },
      data: payload,
    }),
  update_info: (payload, type, token) =>
    createRequest({
      method: "PUT",
      url: `/update_info/${type}`,
      headers: { token },
      data: payload,
    }),
  uploadInfo: (payload, type, token) =>
    createRequest({
      method: "POST",
      url: `/uploadInfo/${type}`,
      headers: { token },
      data: payload,
    }),
  uploadFile: (payload, type, token) =>
    createRequest({
      method: "POST",
      url: `/uploadFile/${type}`,
      headers: { token },
      data: payload,
    }),
  updateInfo: (payload, token) =>
    createRequest({
      method: "PUT",
      url: "/updateInfo",
      headers: { token },
      data: payload,
    }),
  deleteFile: (id, type, token) =>
    createRequest({
      method: "PUT",
      url: `/deleteFile/${id}/${type}`,
      headers: { token },
    }),
  deletePreviousDict: (page, token) =>
    createRequest({
      method: "PUT",
      url: "/deleteBanner",
      headers: { token },
      data: { page },
    }),
  adminSignIn: (payload) =>
    createRequest({
      method: "POST",
      url: "/signIn",
      data: payload,
    }),
  getImages: () =>
    createRequest({
      method: "GET",
      url: "/getImages",
    }),
  getFiles: () =>
    createRequest({
      method: "GET",
      url: "/getFiles",
    }),
  getContent: () =>
    createRequest({
      method: "GET",
      url: "/getContent",
    }),
  createContent: (payload, token) =>
    createRequest({
      method: "POST",
      url: "/updateContent",
      headers: { token },
      data: payload,
    }),
};

export default Requests;
