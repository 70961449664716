import { useStore } from "../../Store/Provider/Connect";
import { generateKey } from "../../Utility/helpers";
import Banner from "../Presentational/Banner";
import Title from "../Presentational/Title";
import Blurp from "../Presentational/Blurp";
import RepsCard from "../Presentational/Cards/RepsCards";
import Loading from "../Presentational/Loading";

function Voter() {
  const { getState } = useStore(),
    {
      voter: {
        banner: { src },
        content: { blurp },
        files,
      },
    } = getState();

  return (
    <div>
      <Banner text={"Voter Resources"} bgimg={src} />
      <Title text={"Candidates We Endorse"} />
      <Blurp text={blurp} />

      <div className="flex justify-center px-4 py-8">
        <div className="grid gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          {files.length ? (
            files.map(({ title, _name, email, url, image: { src } }, i) => (
              <RepsCard
                key={generateKey("repcard", i)}
                imageUrl={src}
                title={title}
                name={_name}
                email={email}
                website={url}
              />
            ))
          ) : (
            <Loading />
          )}
        </div>
      </div>
    </div>
  );
}

export default Voter;
