import constants from "../Actions/constants";
const { ADD_HOME_IMAGE, DELETE_HOME_IMAGE, SET_HOME_BANNER, SET_HOME_IMAGES, SET_HOME_CONTENT } =
  constants;

const homeReducer = (state, action) => {
  switch (action?.type) {
    case DELETE_HOME_IMAGE:
      return {
        ...state,
        images: [...state.images].filter(({ _id }) => _id !== action?.payload),
      };
    case ADD_HOME_IMAGE:
      return { ...state, images: [...state.images, action?.payload] };
    case SET_HOME_BANNER:
      return { ...state, banner: action?.payload };
    case SET_HOME_IMAGES:
      return { ...state, images: action?.payload };
    case SET_HOME_CONTENT:
      return { ...state, content: action?.payload };
    default:
      return state;
  }
};

export default homeReducer;
