import { useStore } from "../../Store/Provider/Connect";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getAdminLinks } from "../../Utility/helpers";
import AdminPanel from "../Presentational/AdminPanel";

function AdminLayout() {
  const { getAdminToken, handleAdminToken } = useStore(),
    [signIn, setSignIn] = useState(true),
    [admin, setAdmin] = useState(null);

  const location = useLocation(),
    nav = useNavigate(),
    currentPath = location.pathname,
    hideOutletOnPaths = ["/admin"],
    shouldRenderOutlet = !hideOutletOnPaths.includes(currentPath);

  useEffect(() => {
    let { token } = getAdminToken();

    if (!token) setSignIn(true);
    else {
      let {
        user: { admin: _admin },
      } = getAdminToken();
      setAdmin(_admin);
      setSignIn(false);
    }
  }, [getAdminToken]);

  const handleSignOut = () => {
    handleAdminToken("");
    nav("/admin")
  };

  return (
    <AdminPanel
      signIn={signIn}
      setSignIn={setSignIn}
      handleAdminToken={handleAdminToken}
      getAdminLinks={getAdminLinks}
      shouldRenderOutlet={shouldRenderOutlet}
      admin={admin}
      handleSignOut={handleSignOut}
    />
  );
}

export default AdminLayout;
