import { useStore } from "../../Store/Provider/Connect";
import { useLayoutEffect } from "react";
import { Outlet } from "react-router";
import { getData, setFiles, setContent } from "../../Utility/helpers";
import styles from "../../Utility/styles";
import actions from "../../Store/Actions/action.types";
import NavBar from "../Presentational/NavBar";
import Footer from "../Presentational/Footer";

function Layout() {
  const { dispatcher } = useStore();

  useLayoutEffect(() => {
    async function fetchData() {
      const { images = [], content = [], files = [] } = await getData();

      if (images.length) {
        setFiles(images, [], dispatcher, actions);
      }

      if (files.length) {
        setFiles([], files, dispatcher, actions);
      }

      if (content.length) {
        setContent(content, dispatcher, actions);
      }
    }
    fetchData();
    // eslint-disable-next-line
  }, []);
  return (
    <div style={styles.layoutGridContainer()}>
      <div className="layout-grid-unit">
        <NavBar />
      </div>
      <div className="layout-grid-unit">
        <Outlet />
      </div>
      <div className="layout-grid-unit">
        <Footer />
      </div>
    </div>
  );
}

export default Layout;
