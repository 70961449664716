import React, { useContext, createContext, useReducer, useState } from "react";
import rootReducer from "../Reducers/root.reducer";
import initialState from "../State/initialState";
const Store = createContext();

export const useStore = () => useContext(Store);

function Connect({ children }) {
  const [state, dispatch] = useReducer(rootReducer, initialState);
  const [adminToken, setAdminToken] = useState({});

  const getState = () => JSON.parse(JSON.stringify(state));
  const dispatcher = (payload) => dispatch(payload);
  const getAdminToken = () => adminToken;
  const handleAdminToken = (token) => setAdminToken(token);

  return (
    <Store.Provider
      value={{ getState, dispatcher, getAdminToken, handleAdminToken }}
    >
      {children}
    </Store.Provider>
  );
}

export default Connect;
