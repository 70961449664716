import CarouselDisplay from "./CarouselDisplay";
import { generateKey } from "../../Utility/helpers";

const Files = ({ data, deleteCaroselItem, type }) =>
  data.map(({ image: { src }, _id, _type }, i) => (
    <CarouselDisplay
      key={generateKey(`carouselDisplay`, i)}
      src={src}
      deleteCaroselItem={deleteCaroselItem}
      id={_id}
      type={_type ? _type : type}
    />
  ));

export default Files;
