import styles from "../../Utility/styles";
import BlogUpdate from "./BlogUpdate";
import CarouselUpdate from "./CarouselUpdate";
import UpdateInfoField from "./UpdateInfoField";

const UpdateFieldModal = ({
  modalFile,
  modalImage,
  deleteCaroselItem,
  handleFileUpload,
  setIsUploaded,
  isUploaded,
  type,
  _type,
  handleInfo,
  handleSubmitInfo,
  formData,
  get_type,
  page,
  title,
  modalSubmit,
}) => {
  return (
      <div style={styles.updateFieldModalContainer()}>
        <div style={styles.modalFieldsContainer(_type)}>
          <UpdateInfoField
            handleSubmitInfo={handleSubmitInfo}
            handleInfo={handleInfo}
            _type={_type}
            title={title}
            isUploaded={isUploaded}
            hasData={modalSubmit?.info?._name}
          />
          {["blog"].includes(_type) && (
            <BlogUpdate
              hasData={formData.blog}
              handleFileUpload={handleFileUpload}
              setIsUploaded={setIsUploaded}
              handleInfo={handleInfo}
              isUploaded={isUploaded}
              modalFile={modalFile}
              formData={formData}
              _type={_type}
            />
          )}
          {["pdf"].includes(_type) && (
            <CarouselUpdate
              data={modalImage}
              modalImage
              modalFile={modalFile}
              deleteCaroselItem={deleteCaroselItem}
              handleFileUpload={handleFileUpload}
              setIsUploaded={setIsUploaded}
              isUploaded={isUploaded}
              type={type}
              _type={_type}
              modal
              formData={formData}
              get_type={get_type}
              page={page}
              title={title}
            />
          )}
        </div>
      </div>
  );
};

export default UpdateFieldModal;
