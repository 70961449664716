import { useStore } from "../../Store/Provider/Connect";
import { generateKey } from "../../Utility/helpers.js";
import Banner from "../Presentational/Banner.jsx";
import Blurp from "../Presentational/Blurp";
import PartnersCard from "../Presentational/Cards/PartnersCard.jsx";
import Loading from "../Presentational/Loading.jsx";

function Partners() {
  const { getState } = useStore(),
    {
      partners: {
        banner: { src },
        content: { blurp },
        files,
      },
    } = getState();

  return (
    <div>
      <Banner bgimg={src} text={"Partners"} />
      <Blurp text={blurp} />
      <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 m-8 justify-items-center ">
        {files.length ? (
          files.map(({ email, image: { src }, phone, title, url, _name }, i) => (
            <PartnersCard
              key={generateKey("partners", i)}
              logo={src}
              title={_name}
              name={title}
              email={email}
              phone={phone}
              website={url}
            />
          ))
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
}
export default Partners;
