import constants from "../Actions/constants";
const {
  SET_RESOURCES_BANNER,
  SET_RESOURCES_CONTENT,
  SET_RESOURCES_IMAGES,
  ADD_RESOURCES_IMAGE,
  DELETE_RESOURCES_IMAGE,
  ADD_RESOURCES_FILE,
  DELETE_RESOURCES_FILE,
  SET_RESOURCE_FILES
} = constants;

const resourcesReducer = (state, action) => {
  
  switch (action?.type) {
    case SET_RESOURCES_BANNER:
      return { ...state, banner: action?.payload };
    case SET_RESOURCES_CONTENT:
      return { ...state, content: action?.payload };
    case SET_RESOURCES_IMAGES:
      return { ...state, images: action?.payload };
    case DELETE_RESOURCES_IMAGE:
      return {
        ...state,
        images: [...state.images].filter(({ _id }) => _id !== action?.payload),
      };
    case ADD_RESOURCES_IMAGE:
      return { ...state, images: [...state.images, action?.payload] };
    case ADD_RESOURCES_FILE:
      return { ...state, files: [...state.files, action?.payload] };
    case DELETE_RESOURCES_FILE:
      return {
        ...state,
        files: [...state.files].filter(({ _id }) => _id !== action?.payload),
      };
    case SET_RESOURCE_FILES: 
      return {...state, files: action?.payload}
    default:
      return state;
  }
};

export default resourcesReducer;
