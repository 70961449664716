const Title = ({ text }) => {
  return (
    <div className="flex justify-center text-center my-4">
      <h1 className="text-4xl lg:text-3xl md:text-2xl sm:text-xl xs:text-lg">
        {text}
      </h1>
    </div>
  );
};

export default Title;
