import React from "react";

const RepsCard = ({ imageUrl, title, name, email, website }) => {
  return (
    <div className="shadow-lg flex flex-col items-center w-72 h-72 m-2.5 bg-white rounded-lg p-2">
      {imageUrl && (
        <img
          className="w-full h-36 object-cover"
          src={`${imageUrl}`}
          alt={title}
        />
      )}
      <div className="mt-2 text-primaryRed text-xl font-medium">
        <p>{name}</p>
      </div>
      <div className="text-secondaryBlue text-md font-medium">
        <p>{title}</p>
      </div>
      <div className="text-secondaryBlue text-md">
        <p>{email}</p>
      </div>
      <div className="text-secondaryBlue text-md">
        <a
          href={"https://" + website}
          target="_blank"
          rel="noreferrer"
          className="text-secondaryBlue"
        >
          {website}
        </a>
      </div>
    </div>
  );
};

export default RepsCard;
