import React from "react";

const PartnersCard = ({ logo, title, name, email, phone, website, city }) => {
  return (
    <div className="flex items-center bg-white shadow-md rounded-lg p-4 max-w-lg m-2">
      <div className="w-24 flex items-center">
        <img src={logo} className="h-32 xl:w-48 lg:w-48 md:w-48 sm:w-32 xs:w-24" alt={logo} />
      </div>

      <div className="xl:w-64 lg:w-64 md:w-64 sm:w-56 xs:w-56 pl-4">
        <h3 className="text-lg font-semibold text-primaryRed">{title}</h3>
        <p className="text-sm font-medium text-secondaryBlue">{name}</p>
        <p className="text-sm text-secondaryBlue">{email}</p>
        <p className="text-sm text-secondaryBlue">{phone}</p>
        <a
          href={"https://" + website}
          className="xl:text-sm lg:text-sm md:text-sm sm:text-xs xs:text-xs text-blue-500 hover:underline"
        >
          {website}
        </a>
        <p className="text-sm text-secondaryBlue">{city}</p>
      </div>
    </div>
  );
};

export default PartnersCard;
