import { useStore } from "../../Store/Provider/Connect";
import actions from "../../Store/Actions/action.types";
import React, { useState } from "react";
import {
  uploadFile,
  dispatchFile,
  upload_file,
  deleteFormKeys,
  getTitle,
  resetState,
} from "../../Utility/helpers";
import Requests from "../../Utility/Requests";
import styles from "../../Utility/styles";
import UpdateField from "../Presentational/UpdateField";
import CarouselUpdate from "../Presentational/CarouselUpdate";
import UpdateFieldModal from "../Presentational/UpdateFieldModal";
let formData = new FormData();

function UpdatePage({ page }) {
  const { getState, dispatcher, getAdminToken } = useStore(),
    {
      token,
      user: { admin },
    } = getAdminToken(),
    [isUploaded, setIsUploaded] = useState({
      banner: false,
      image: false,
      content: false,
      file: false,
      modalField: false,
    }),
    [input, setInput] = useState({}),
    [modal, setModal] = useState(false),
    [modalImage, setModalImage] = useState([]),
    [modalFile, setModalFile] = useState(null),
    [, set_type] = useState("");

  const handleInput = (e) => {
    setIsUploaded((prev) => ({ ...prev, content: true }));
    setInput((prev) => ({ ...prev, location: page, blurp: e.target.value.trim() }));
  };

  const handleInfo = (e) => {
    setIsUploaded((prev) => ({ ...prev, modalField: true }));
    setInput((prev) => ({
      ...prev,
      info: { ...prev.info, [e.target.name]: e.target.value.trim() },
    }));
  };

  const deleteModalImage = () => setModalImage([]);

  const deleteCaroselItem = async (id, type) => {
    try {
      const response = await Requests.deleteFile(id, type, token);
      const status = response?.status;

      if (status === 201) {
        dispatcher(actions.deleteFile(page, id, type));
      } else {
        console.warn(`Unexpected status code: ${status}`);
      }
    } catch (err) {
      console.error("Error deleting carousel item:", err);
    }
  };

  const handleFileUpload = async (e, file, type, isModalImage = false) => {
    e.preventDefault();

    const {
      _name = "",
      title = "",
      email = "",
      url = "",
      blog = "",
      phone = "",
    } = input?.info || {};
    if (isModalImage) {
      try {
        [
          ["_name", _name],
          ["title", title],
          ["email", email],
          ["url", url],
          ["location", page],
          ["blog", blog],
          ["author", admin],
          ["phone", phone],
        ].forEach(([key, value]) => file.append(key, value));

        const { files, status } = await upload_file(e, file, type, token);
        if (status === 201) {
          deleteFormKeys(file);
          dispatcher(actions.setFiles(page, files));
          setIsUploaded({ image: false, file: false, input: false });
          resetState({
            setIsUploaded,
            setInput,
            setModal,
            setModalImage,
            setModalFile,
            set_type,
          });
        } else {
          console.warn(`Unexpected status code for modal image: ${status}`);
          deleteFormKeys(file);
        }
      } catch (err) {
        console.error("Error uploading modal image:", err);
      }
      return;
    }

    const validTypes = ["pdf", "rep", "link"];
    const validPages = ["resources", "voter", "links", "blog", "partners"];

    if (
      validTypes.includes(type) ||
      (validPages.includes(page) && !isModalImage)
    ) {
      set_type(get_type(page));
      setModalFile(file);
      setModal(true);
      return;
    }

    try {
      const { final, status } = await uploadFile(e, file, page, type, token);
      if (status === 201) {
        dispatchFile(dispatcher, actions, type, page, final);
        resetState({
          setIsUploaded,
          setInput,
          setModal,
          setModalImage,
          setModalFile,
          set_type,
        });
        file = new FormData();
      } else {
        console.warn(`Unexpected status code: ${status}`);
      }
    } catch (error) {
      console.error("Error during file upload:", error);
    }
  };

  const get_type = (page) => {
    const typeMap = {
      resources: "pdf",
      voter: "rep",
      contact: "link",
      blog: "blog",
      links: "link",
      partners: "partners",
    };

    return typeMap[page] || null;
  };

  const handleSubmitInfo = async (e) => {
    e.preventDefault();
    handleFileUpload(e, modalFile, get_type(page), true);
  };

  const handleBannerUpdate = async (e, form) => {
    e.preventDefault();

    try {
      Requests.deletePreviousDict(page, token);

      const { final, status } = await uploadFile(
        e,
        form,
        `banner-${page}`,
        "image",
        token
      );

      if (status === 201) {
        dispatcher(actions.setBanner(page, final));
        setIsUploaded((prev) => ({ ...prev, banner: false }));
        form = new FormData();
      } else {
        console.warn(`Unexpected status code: ${status}`);
        form = new FormData();
      }
    } catch (error) {
      console.error("Error handling banner update:", error);
    }
  };

  const handleContentUpdate = async (e) => {
    e.preventDefault();

    try {
      const { data, status } = await Requests.createContent(input, token);
      if (status === 201) {
        dispatcher(actions.setContent(page, data));
        setIsUploaded((prev) => ({ ...prev, content: false }));
        setInput({});
      } else {
        console.warn(`Unexpected status code: ${status}`);
      }
    } catch (error) {
      console.error("Error updating content:", error);
    }
  };

  return (
    <div style={styles.updateCaroselMain()}>
      {modal && (
        <UpdateFieldModal
          data={
            page === "links"
              ? getState().resources.files
              : getState()[page].files
          }
          modalFile={modalFile}
          modalImage={modalImage}
          deleteCaroselItem={deleteModalImage}
          handleFileUpload={handleFileUpload}
          setIsUploaded={setIsUploaded}
          isUploaded={isUploaded.modalField}
          type="image"
          _type={get_type(page)}
          handleInfo={handleInfo}
          handleSubmitInfo={handleSubmitInfo}
          modalSubmit={input}
          formData={formData}
          get_type={get_type}
          page={page}
          title={getTitle(get_type, page)}
          hasData={
            page === "links"
              ? Object.keys(getState().resources.files).length
              : Object.keys(getState()[page].files).length
          }
        />
      )}
      {getState()[page]?.banner && (
        <UpdateField
          title="Banner"
          type="file"
          _type={"image"}
          onSubmit={(e) => handleBannerUpdate(e, formData)}
          onChange={(e) => formData.append("image", e.target.files[0])}
          setIsUploaded={setIsUploaded}
          isUploaded={isUploaded.banner}
          uploadType={"banner"}
          hasData={Object.keys(getState()[page].banner).length || 0}
          image={getState()[page]?.banner}
          form
          page={page}
        />
      )}

      {getState()[page]?.content && (
        <UpdateField
          title="Content"
          type="textarea"
          onSubmit={(e) => handleContentUpdate(e)}
          onChange={(e) => handleInput(e)}
          isUploaded={isUploaded.content}
          uploadType={"content"}
          name={page}
          hasData={Object.keys(getState()[page].content).length}
          content={getState()[page].content.blurp}
          form
          get_type={get_type}
          page={page}
        />
      )}

      {getState()[page]?.images && (
        <CarouselUpdate
          data={getState()[page].images || []}
          deleteCaroselItem={deleteCaroselItem}
          handleFileUpload={handleFileUpload}
          setIsUploaded={setIsUploaded}
          isUploaded={isUploaded.image}
          type="image"
          formData={formData}
          get_type={get_type}
          page={page}
        />
      )}

      {(getState()[page]?.files || page === "links") && (
        <CarouselUpdate
          data={
            page === "links"
              ? getState().resources.files.filter(
                  (file) => file._type === "link"
                )
              : page === "resources"
              ? getState()[page]?.files.filter((file) => file._type === "pdf")
              : getState()[page].files
          }
          deleteCaroselItem={deleteCaroselItem}
          handleFileUpload={handleFileUpload}
          setIsUploaded={setIsUploaded}
          isUploaded={isUploaded.file}
          type="file"
          _type={
            ["pdf", "link", "rep", "blog", "partners"].includes(get_type(page))
              ? get_type(page)
              : null
          }
          set_type={set_type}
          get_type={get_type}
          page={page}
          formData={formData}
          title={getTitle(get_type, page)}
        />
      )}
    </div>
  );
}

export default UpdatePage;
