import { useStore } from "../../Store/Provider/Connect";
import { generateKey } from "../../Utility/helpers";
import Banner from "../Presentational/Banner";
import Blurp from "../Presentational/Blurp";
import BlogCard from "../Presentational/Cards/BlogCard";
import Loading from "../Presentational/Loading";

function Blog() {
  const { getState } = useStore(),
    {
      blog: {
        banner: { src },
        content: { blurp },
        files,
      },
    } = getState();

  return (
    <div>
      <Banner text="Articles" bgimg={src} />
      <Blurp text={blurp} />

      <div className=" px-4 py-8 flex justify-center">
        <div className="grid gap-6 xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          {files.length ? (
            files.map(({ _name, blog, updatedAt, image: { src } }, i) => (
              <BlogCard
                key={generateKey("blogcard", i)}
                title={_name}
                blogPosts={blog}
                date={updatedAt}
                imageUrl={src}
              />
            ))
          ) : (
            <Loading />
          )}
        </div>
      </div>
    </div>
  );
}

export default Blog;
