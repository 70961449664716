const actions = {
  ADD_HOME_IMAGE: "ADD_HOME_IMAGE",
  SET_HOME_BANNER: "SET_HOME_BANNER",
  SET_HOME_IMAGES: "SET_HOME_IMAGES",
  DELETE_HOME_IMAGE: "DELETE_HOME_IMAGE",
  SET_HOME_CONTENT: "SET_HOME_CONTENT",

  SET_ABOUT_BANNER: "SET_ABOUT_BANNER",

  SET_RESOURCES_BANNER: "SET_RESOURCES_BANNER",
  SET_RESOURCES_CONTENT: "SET_RESOURCES_CONTENT",
  SET_RESOURCES_IMAGES: "SET_RESOURCES_IMAGES",
  DELETE_RESOURCES_IMAGE: "DELETE_RESOURCES_IMAGE",
  ADD_RESOURCES_IMAGE: "ADD_RESOURCES_IMAGE",
  ADD_RESOURCES_FILE: "ADD_RESOURCES_FILE",
  DELETE_RESOURCES_FILE: "DELETE_RESOURCES_FILE",
  SET_RESOURCE_FILES: "SET_RESOURCE_FILES",

  SET_VOTER_CONTENT: "SET_VOTER_CONTENT",
  SET_VOTER_FILES: "SET_VOTER_FILES",
  SET_VOTER_BANNER: "SET_VOTER_BANNER",
  DELETE_VOTER_FILE: "DELETE_VOTER_FILE",

  SET_BLOG_FILES: "SET_BLOG_FILES",
  SET_BLOG_BANNER: "SET_BLOG_BANNER",
  SET_BLOG_CONTENT: "SET_BLOG_CONTENT",
  DELETE_BLOG_FILE: "DELETE_BLOG_FILE",

  SET_CONTACT_BANNER: "SET_CONTACT_BANNER",
  SET_CONTACT_FILES: "SET_CONTACT_FILES",
  SET_CONTACT_CONTENT: "SET_CONTACT_CONTENT",
  DELETE_CONTACT_FILE: "DELETE_CONTACT_FILE",

  SET_GETINVOLVED_BANNER: "SET_GETINVOLVED_BANNER",
  SET_GETINVOLVED_IMAGES: "SET_GETINVOLVED_IMAGES",
  ADD_GETINVOLVED_IMAGE: "ADD_GETINVOLVED_IMAGE",
  DELETE_GETINVOLVED_IMAGE: "DELETE_GETINVOLVED_IMAGE",
  SET_GETINVOLVED_CONTENT: "SET_GETINVOLVED_CONTENT",

  SET_PARTNERS_BANNER: "SET_PARTNERS_BANNER",
  SET_PARTNERS_CONTENT: "SET_PARTNERS_CONTENT",
  SET_PARTNERS_FILES: "SET_PARTNERS_FILES",
  ADD_PARTNERS_FILE: "ADD_PARTNERS_FILE",
  DELETE_PARTNERS_FILE: "DELETE_PARTNERS_FILE",
};

export default actions;
