import styles from "../../Utility/styles";

const NoFiles = ({ _type, modal }) => (
  <p
    style={{
      color: "red",
      fontSize: "xx-large",
      ...styles.fontTextOne(),
      textAlign: "center",
    }}
  >
    {modal
      ? `You don't have an image for this ${_type}!`
      : `You don't have any ${_type === "partners" ? "partners" : _type + "'s"}`}
  </p>
);

export default NoFiles;
