import styles from "../../Utility/styles";

const BlogUpdate = ({
  hasData,
  handleFileUpload,
  setIsUploaded,
  handleInfo,
  isUploaded,
  modalFile,
  _type
}) => (
  <div style={styles.blogUpdateContainer()}>
    <form
      onSubmit={(e) => handleFileUpload(e, modalFile, _type, true)}
      style={styles.blogForm()}
    >
      <div style={styles.blogTitleContainer()}>
        <h3
          style={{
            ...styles.updateFieldTitle(),
            ...styles.fontTextOne(hasData),
            paddingRight: "8%",
          }}
        >
          Enter Blog Content
        </h3>
      </div>
      <div style={styles.blogInputContainer()}>
        <input
          onChange={(e) => {
            setIsUploaded((prev) => ({ ...prev, blog: true }));
            handleInfo(e);
          }}
          name="blog"
          type="textarea"
          style={styles.blogInput()}
          placeholder="Your 2 cents here"
        />
      </div>
      <button style={styles.updateFieldUpload(isUploaded)}>Upload</button>
    </form>
  </div>
);

export default BlogUpdate;
