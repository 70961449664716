import { useStore } from "../../Store/Provider/Connect";
import Calendar from "../Presentational/Calendar";
import Banner from "../Presentational/Banner";
import Blurp from "../Presentational/Blurp";
import GetInvolvedImages from "../Presentational/GetInvolvedImages";

function GetInvolved() {
  const { getState } = useStore(),
    {
      getInvolved: {
        banner: { src },
        content: { blurp },
        images,
      },
    } = getState();

  return (
    <div>
      <Banner text="Get Involved" bgimg={src} />
      <Blurp text={blurp} />
      <div className="flex justify-center p-4">
        <div className="grid justify-items-center grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-4">
          <Calendar />
          <GetInvolvedImages images={images} />
        </div>
      </div>
    </div>
  );
}

export default GetInvolved;
