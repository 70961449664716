import constants from "../Actions/constants";
const {
  SET_GETINVOLVED_IMAGES,
  SET_GETINVOLVED_BANNER,
  ADD_GETINVOLVED_IMAGE,
  DELETE_GETINVOLVED_IMAGE,
  SET_GETINVOLVED_CONTENT
} = constants;

const getInvolvedReducer = (state, action) => {
  switch (action?.type) {
    case SET_GETINVOLVED_BANNER:
      return { ...state, banner: action?.payload };
    case SET_GETINVOLVED_IMAGES:
      return { ...state, images: action?.payload };
    case ADD_GETINVOLVED_IMAGE:
      return { ...state, images: [...state.images, action?.payload] };
    case DELETE_GETINVOLVED_IMAGE:
      return {
        ...state,
        images: [...state.images].filter(({ _id }) => _id !== action?.payload),
      };
    case SET_GETINVOLVED_CONTENT:
      return { ...state, content: action?.payload };
    default:
      return state;
  }
};

export default getInvolvedReducer;
