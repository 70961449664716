import aboutReducer from "./about.reducer";
import blogReducer from "./blog.reducer";
import homeReducer from "./home.reducer";
import resourcesReducer from "./resources.reducer";
import voterReducer from "./voter.reducer";
import contactReducer from "./contact.reducer";
import getInvolvedReducer from "./getInvolved.reducer";
import partnersReducer from "./partners.reducer";

const rootReducer = (state, action) => ({
  home: homeReducer(state.home, action),
  about: aboutReducer(state.about, action),
  resources: resourcesReducer(state.resources, action),
  voter: voterReducer(state.voter, action),
  blog: blogReducer(state.blog, action),
  getInvolved: getInvolvedReducer(state.getInvolved, action),
  contact: contactReducer(state.contact, action),
  partners: partnersReducer(state.partners, action),
});

export default rootReducer;
