import React from "react";

const ResourceCard = ({
  linkbgImg,
  pdfbgImg,
  text,
  link,
  linkbuttontext,
  pdfbuttontext,
}) => {
  return (
    <div className="flex flex-col w-64 h-72 bg-white shadow-lg m-2.5">
      {linkbgImg && (
        <div
          className="bg-cover bg-center w-11/12 h-2/3 m-2.5"
          style={{ backgroundImage: `url(${linkbgImg})` }}
        ></div>
      )}
      {pdfbgImg && (
        <div
          className="bg-cover bg-center w-11/12 h-2/3 m-2.5"
          style={{ backgroundImage: `url(${pdfbgImg})` }}
        ></div>
      )}

      <div className="flex flex-col items-center">
        <p className="text-center text-md py-1 text-[#3c3b6e]">{text}</p>
      </div>

      <div className="flex flex-col items-center">
        {linkbuttontext && (
          <a
            href={
              link.startsWith("http")
                ? link
                : `https://${link.replace(/^\/+/, "")}`
            }
            target="_blank"
            rel="noopener noreferrer"
            className="w-full flex justify-center"
          >
            <button className="bg-primaryRed w-11/12 text-white px-1 py-1 rounded-md">
              {linkbuttontext}
            </button>
          </a>
        )}

        {pdfbuttontext && (
          <a
            href={
              link.startsWith("http")
                ? link
                : `https://${link.replace(/^\/+/, "")}`
            }
            target="_blank"
            rel="noopener noreferrer"
            className="w-full flex justify-center"
          >
            <button className="bg-primaryRed w-11/12 text-white px-1 py-1 rounded-md">
              {pdfbuttontext}
            </button>
          </a>
        )}
      </div>
    </div>
  );
};

export default ResourceCard;
