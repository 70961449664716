import "./global.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./Views/Container/Layout";
import AdminLayout from "./Admin_Panel/Container/AdminLayout";
import UpdatePage from "./Admin_Panel/Container/UpdatePage";
import Home from "./Views/Container/Home";
import About from "./Views/Container/About";
import Resources from "./Views/Container/Resources";
import Voter from "./Views/Container/Voter";
import Contact from "./Views/Container/Contact";
import Blog from "./Views/Container/Blog";
import GetInvolved from "./Views/Container/getInvolved";
import Partners from "./Views/Container/Partners";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home page="home" />} />
          <Route path="about" element={<About page="about" />} />
          <Route path="resources" element={<Resources page="resources" />} />
          <Route path="voter" element={<Voter page="voter" />} />
          <Route path="articles" element={<Blog page="blog" />} />
          <Route path="contact" element={<Contact page="contact" />} />
          <Route path="events" element={<GetInvolved page="events" />} />
          <Route path="partners" element={<Partners page="partners" />} />
        </Route>

        <Route path="admin" element={<AdminLayout />}>
          <Route path="home" element={<UpdatePage page="home" />} />
          <Route path="about" element={<UpdatePage page="about" />} />
          <Route path="files" element={<UpdatePage page="resources" />} />
          <Route path="voter" element={<UpdatePage page="voter" />} />
          <Route path="blog" element={<UpdatePage page="blog" />} />
          <Route path="events" element={<UpdatePage page="getInvolved" />} />
          <Route path="links" element={<UpdatePage page="links" />} />
          <Route path="contact" element={<UpdatePage page="contact" />} />
          <Route path="partners" element={<UpdatePage page="partners" />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
