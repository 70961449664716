const styles = {
  welcomeContainer: () => ({
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }),
  welcomeText: () => ({
    fontSize: "45px",
    fontWeight: "bolder",
    color: "#0A2240",
    textShadow: "0px 1px 3px black",
  }),
  adminSignInContainer: (logo) => ({
    flexDirection: "column",
    backgroundImage: `url(${logo})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: "100%",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }),
  adminSignInForm: () => ({
    width: "30%",
    height: "70%",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    background: "whitesmoke",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    color: "#000",
    padding: "20px"
  }),
  signInInput: () => ({
    width: "50%",
    height: "5%",
    borderRadius: "3px",
  }),
  adminNavContainer: () => ({
    height: "10vh",
    width: "100vw",
    display: "grid",
    gridTemplateColumns: "repeat(10, 1fr)",
    gap: "1%",
    marginLeft: "1.5%"
  }),
  adminNavUnit: () => ({
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Helvetica",
    fontWeight: "100",
    fontSize: "small",
  }),
  adminMainContainer: () => ({
    marginTop: "1vh",
    height: "88vh",
  }),
  updateHomeMain: () => ({
    display: "grid",
  }),
  updateCaroselMain: () => ({
    height: "85vh",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    flex: "1",
  }),
  caroselContainer: (modal) => ({
    display: "grid",
    gridTemplateColumns: modal ? "300px" : "repeat(4, 23%)",
    justifyContent: modal ? "center" : null,
    alignItems: modal ? "center" : null,
    gridAutoRows: "270px",
    width: modal ? "30%" : "93%",
    gap: "10% 2%",
    height: "46%",
    overflow: "scroll",
    border: "1px inset gray",
    borderRadius: "5px",
    paddingLeft: "1.5%",
    paddingTop: "1.5%",
    paddingBottom: "1.5%",
  }),
  caroselDisplayContainer: () => ({
    height: "85%",
    width: "100%",

    boxShadow: "2px 5px 15px black",
  }),
  imageCardContainer: () => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
    width: "100%",
    flexDirection: "column",
  }),
  imageElement: () => ({
    height: "100%",
    width: "100%",
  }),
  deleteContainer: () => ({
    height: "10%",
    width: "30%",
  }),
  deleteButton: () => ({
    width: "100%",
    color: "red",
    fontWeight: "bold",
    height: "100%",
  }),
  homeFlexUnit: () => ({
    display: "flex",
    flexDirection: "column",
    borderRadius: "4px",

    width: "95%",
    border: "1px solid black",
  }),
  updateFieldContainer: (_type) => ({
    borderRadius: "5px",
    border: "1px solid black",
    width: "93%",
    height: "12%",
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    padding: "2%",
  }),
  updateFieldTitle: () => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }),
  updateFieldUnit: () => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "15%",
    height: "100%",
  }),
  updateFieldUnitInput: (image) => ({
    width: image ? "50%" : "80%",
    height: "100%",
  }),
  updateFieldForm: (type) => ({
    display: "flex",
    justifyContent: ["blog", "pdf", "link", 'rep', "partners"].includes(type)
      ? "space-around"
      : "flex-end",
    alignItems: "center",
    width: "100%",
    height: "100%",
  }),
  updateFieldUpload: (isUploaded) => ({
    height: "35px",
    width: "10%",
    backgroundColor: isUploaded ? "red" : null,
    color: isUploaded ? "whitesmoke" : "black",
    borderRadius: "5px",
    border: "1px solid black",
    fontSize: "medium",
    fontFamily: "helvetica",
    fontWeight: isUploaded ? "bolder" : "100",
    marginLeft: "5%",
  }),
  fontTextOne: (hasData) => ({
    fontFamily: "helvetica",
    textShadow: "0px 1px 2px black",
    color: hasData ? "black" : "red",
  }),
  navLink: (isActive, last = false) => ({
    backgroundColor: isActive ? "#0A2240" : null,
    textDecoration: "none",
    color: isActive ? "white" : "black",
    boxShadow: isActive || last ? "0px 3px 4px black" : null,
    borderRadius: "3px",
    height: "35%",
    width: "70%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }),
  bannerContainer: () => ({
    height: "50px",
    width: "200px",
    marginLeft: "7%",
  }),
  updateFieldModalContainer: () => ({
    backgroundColor: "rgba(0,0,0,0.8)",
    width: "100vw",
    height: "100vh",
    zIndex: "1",
    left: "0",
    top: "0",
    position: "fixed",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  }),
  modalFieldsContainer: (_type) => ({
    width: "90%",
    height: ["rep", "link"].includes(_type) ? "30%" : "65%",
    border: "5px outset whitesmoke",
    borderRadius: "5px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    backgroundColor: "whitesmoke",
  }),
  modalFlexUnit: () => ({
    height: "100%",
    width: "95%",
  }),
  blogUpdateContainer: () => ({
    height: "50%",
    width: "95%",
    border: "1px solid black",
    borderRadius: "5px",
  }),
  blogForm: () => ({
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    height: "100%",
  }),
  blogTitleContainer: () => ({
    height: "100%",
    width: "30%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: "0%",
    textShadow: "0px 1px 3px black",
  }),
  blogInputContainer: () => ({
    height: "100%",
    width: "30%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }),
  blogInput: () => ({
    height: "86%",
    width: "100%",
    paddingLeft: "4%",
  }),
  blogTitleText: () => ({
    textShadow: "0px 1px 3px black",
  }),
  layoutGridContainer: () => ({
    display: "grid",
    gridTemplateRows: ".1fr 1fr .1fr",
    height: "110vh",
  }),
  getInvolvedContentContainer: () => ({
    display: "flex",
    height: "79vh",
  }),
  getInvolvedImagesContainer: () => ({
    display: "grid",
    gridTemplateRows: "1fr 1fr",
    gridTemplateColumns: "1fr 1fr",
    height: "79vh",
    width: "38%",
    marginLeft: "1%"
  }),
  getInvolvedImageUnit: () => ({
    height: "100%",
    width: "100%"
  })
};
export default styles;
