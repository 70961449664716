import constants from "../Actions/constants";

const {
  SET_CONTACT_BANNER,
  SET_CONTACT_FILES,
  SET_CONTACT_CONTENT,
  DELETE_CONTACT_FILE,
} = constants;

const contactReducer = (state, action) => {
  switch (action?.type) {
    case SET_CONTACT_BANNER:
      return { ...state, banner: action?.payload };
    case SET_CONTACT_FILES:
      return { ...state, files: action?.payload };
    case SET_CONTACT_CONTENT:
      return { ...state, content: action?.payload };
    case DELETE_CONTACT_FILE:
      return {
        ...state,
        files: [...state.files].filter(({ _id }) => _id !== action?.payload),
      };
    default:
      return state;
  }
};

export default contactReducer;
