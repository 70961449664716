import { useState } from "react";

const PrinciplesCard = ({ title, paragraph }) => {
  const [expanded, setExpanded] = useState(false);

  const expandedHandler = () => {
    setExpanded((prev) => !prev);
  };

  return (
    <>
    <div className="bg-secondaryBlue text-white shadow-md rounded-lg overflow-hidden mb-4 p-4 xl:w-96 lg:w-96 md:w-96 sm:w-72 xs:w-72">
      <div className="p-4">
        <h5 className="text-xl font-semibold mb-2">{title}</h5>
        <p className={`text-base ${expanded ? "block" : "line-clamp-3"}`}>
          {paragraph}
        </p>
      </div>
      <div className="flex items-center justify-between px-4 py-2 border-t border-white">
        <button
          onClick={expandedHandler}
          className="text-white hover:text-gray-200 transition-colors"
        >
          {expanded ? "Read Less" : "Read More"}
        </button>
      </div>
    </div>
    </>
  );
};

export default PrinciplesCard;
