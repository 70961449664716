import CarouselDisplay from "./CarouselDisplay";
import { generateKey } from "../../Utility/helpers";

const HomeImages = ({ data, deleteCaroselItem, type }) =>
  data.map(({ src, _id }, i) => (
    <CarouselDisplay
      key={generateKey(`carouselDisplay`, i)}
      src={src}
      deleteCaroselItem={deleteCaroselItem}
      id={_id}
      type={type}
    />
  ));
export default HomeImages;
