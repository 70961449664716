import { useStore } from "../../Store/Provider/Connect";
import Banner from "../Presentational/Banner";
import PrinciplesCard from "../Presentational/Cards/PrinciplesCard";
import Title from "../Presentational/Title";

function About() {
  const { getState } = useStore(),
    {
      about: {
        banner: { src },
      },
    } = getState();

  return (
    <div>
      <div>
        <Banner bgimg={src} text={"About Us"} />
      </div>
      <Title text={"7 Core Principles of Conservatism"} />
      <div className="flex justify-center mt-4">
        <div className="grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 gap-4">
          <PrinciplesCard
            title="1. Individual Freedom"
            paragraph="The birth of our great nation was inspired by the bold declaration that our individual,God-given liberties should be preserved against government intrusion. That same conviction informs our conservative policy decisions still today. In America,we proclaim the self-evident truths that all of us are created equal and granted by God the same inherent freedoms, such as the natural and unalienable rights to life, liberty, conscience, free speech and the free exercise of religion, and the ability to pursue happiness, own property, build wealth and defend ourselves and our families. The purpose of government is to secure these rights, and the ideas we advance should always aim to maintain and increase the liberty of the American people."
          />
          <PrinciplesCard
            title="2. Limited Government"
            paragraph="For individual liberty to be championed, government must be reduced. We believe, as our founders did, that legitimate government operates only by the consent of the governed, and is more efficient and less corrupt when it is limited in its size and scope. When applied as written, our incomparable Constitution provides important safeguards against government encroachment, a vital separation of powers, and a necessary system of checks and balances. Federalism, decentralized authority, and the elimination of unnecessary regulations and bureaucracy help ensure that government serves the people, and not the other way around. The best protection against government largesse is an engaged and informed electorate."
          />
          <PrinciplesCard
            title="3. The Rule of Law"
            paragraph='Ours is "a government of laws and not of men" and the rule of law is our foundation. To maintain ordered liberty and a civilized society, public and private virtue should be encouraged and justice must be administered equally and impartially to all. Each branch of government must adhere to the Constitution, and the judicial branch must not be allowed to assume or exercise legislative or executive powers. Transparency and accountability are keys to good government, and Congress must faithfully perform its constitutional responsibility of oversight.'
          />
          <PrinciplesCard
            title="4. Peace through Strength"
            paragraph='The first obligation of the federal government is to provide for the "common defense" of the United States by securing our borders and protecting our homeland and our strategic interests abroad. We are not "the world’s police force," but because America serves in a natural role of moral leadership in an increasingly dangerous world, and weakness invites aggression, we must remain the strongest military power on earth—fully prepared and capable of defeating any adversaries, tyrants or terrorists, under any circumstances, at any time. This requires maintaining sufficient investments in our air, land, sea, nuclear, and cyber capabilities to deter foreign aggression, and properly training, equipping, and supporting our troops and their families, as well as our veterans and wounded warriors.'
          />
          <PrinciplesCard
            title="5. Fiscal Responsibility"
            paragraph="Because government has refused to live within its means, America is facing an unprecedented debt and spending crisis. Federal debt now exceeds $33.5 trillion, and our current fiscal path is unsustainable and dangerous,jeopardizing our nation's economic growth, stability and the security of future generations. Congress has a moral and constitutional duty to resolve the crisis, bring spending under control, balance the federal budget, reform and modernize entitlement programs, eliminate fraud, waste and abuse, pursue continued pro-growth tax reforms and permanent tax reductions, and restore regular order and accountability in the budget and appropriations processes."
          />
          <PrinciplesCard
            title="6. Free Markets"
            paragraph="Government often stands as the greatest obstacle to the progress and prosperity of free people. Free markets and free trade agreements allow for innovation, improvement and economic expansion as risk-takers, entrepreneurs and business owners are given the liberty to pursue the American dream and create more jobs and upward mobility for more people. We believe competition should be encouraged, and government intervention and regulation should be limited. The people are better qualified to make decisions about their own lives and finances than bureaucrats, and the private sector will outperform the public sector in virtually every scenario. The free enterprise system rewards hard work and self-sacrifice, and is the basis and genius of the American economy."
          />
          <div className="xl:col-span-3 lg:col-span-2 md:col-span-1 sm:col-span-1 flex justify-center">
            <PrinciplesCard
              title="7. Human Dignity"
              paragraph="Because all men are created equal and in the image of God, every human life has inestimable dignity and value, and every person should be measured only by the content of their character. A just government protects life, honors marriage and family as the primary institutions of a healthy society, and embraces the vital cultural influences of religion and morality. Public policy should always encourage education and emphasize the virtue of hard work as a pathway out of-poverty, while public assistance programs should be reserved only for those who are truly in need. In America, everyone who plays by the rules should get a fair shot. By preserving these ideals, we will maintain the goodness of America that has been the secret to our greatness."
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
