const GetInvolvedImages = ({ images }) => (
  <div className="hidden md:grid lg:grid xl:grid grid-rows-2 grid-flow-col gap-4">
    <div className="row-span-2 h-[315px]">
      <img
        src={images[0]?.src}
        alt="Get Involved"
        className="w-full h-full object-cover"
        style={{ maxHeight: "100%", maxWidth: "100%" }}
      />
    </div>
    <div className="row-start-2 h-[315px]">
      <img
        src={images[1]?.src}
        alt="Get Involved 2"
        className="w-full h-full object-cover"
        style={{ maxHeight: "100%", maxWidth: "100%" }}
      />
    </div>
  </div>
);
export default GetInvolvedImages;
