import UpdateField from "./UpdateField";
import NoFiles from "./NoFile";
import ImageWrapper from "./ImageWrapper";
import HomeImages from "./HomeImages";
import Files from "./Files";

const CarouselUpdate = ({
  data,
  modalImage,
  modalFile,
  deleteCaroselItem,
  handleFileUpload,
  setIsUploaded,
  isUploaded,
  type,
  _type,
  modal,
  get_type,
  page,
  formData,
  title,
}) => {
  const isFileType = ["pdf", "rep", "blog", "link", "partners"].includes(_type);
  const shouldShowFiles = isFileType && !modalImage;

  return (
    <>
      {data.length && !modal ? (
        <ImageWrapper modal={modal}>
          {shouldShowFiles ? (
            <Files
              data={data}
              deleteCaroselItem={deleteCaroselItem}
              type={type}
            />
          ) : (
            <HomeImages
              data={data}
              deleteCaroselItem={deleteCaroselItem}
              type={type}
            />
          )}
        </ImageWrapper>
      ) : (
        <NoFiles _type={_type || "image"} modal={modal} />
      )}
      <UpdateField
        title={
          modal
            ? `Image`
            : ["pdf", "blog", "link"].includes(_type)
            ? `${title}`
            : "Image"
        }
        type="file"
        onSubmit={(e) => {
          modal
            ? handleFileUpload(e, modalFile, _type, true)
            : handleFileUpload(e, formData, _type ? _type : type);
        }}
        onChange={(e) => {
          if (!modal) {
            formData.delete("file");
          }
          formData.delete("image");
          formData.append(
            ["rep", "link", "blog", "partners"].includes(_type) ? "image" : type,
            e.target.files[0]
          );
        }}
        setIsUploaded={setIsUploaded}
        isUploaded={isUploaded}
        uploadType={type}
        hasData={data.length}
        form
        get_type={get_type}
        page={page}
        formData={formData}
      />
    </>
  );
};
export default CarouselUpdate;
