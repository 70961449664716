const Calendar = () => (
  <>
    <iframe
      src="https://calendar.google.com/calendar/embed?src=cochiserepublicanevents%40gmail.com&ctz=America%2FPhoenix"
      width="100%"
      height="630"
      title="Events Calendar"
    ></iframe>
  </>
);

export default Calendar;
