import React, { useState } from "react";

const ImageCarousel = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const getPrevIndex = () =>
    currentIndex === 0 ? images.length - 1 : currentIndex - 1;
  const getNextIndex = () =>
    currentIndex === images.length - 1 ? 0 : currentIndex + 1;

  return (
    <div className="relative flex items-center justify-center my-8">
      <button
        onClick={prevSlide}
        className="absolute left-0 z-10 text-white bg-gray-800 px-4 py-2"
      >
        Prev
      </button>

      <div className="flex xl:space-x-6 lg:space-x-6 md:space-x-6 sm:space-x-6 xs:space-x-4 items-center">
        <div className="w-1/4 h-56 opacity-70">
          <img
            src={images[getPrevIndex()]}
            alt="Previous"
            className="w-full h-full object-cover rounded-lg"
          />
        </div>

        <div className="xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-full xs:w-full xl:h-80 lg:h-80 md:h-80 sm:h-85 xs:h-85 transform scale-110">
          <img
            src={images[currentIndex]}
            alt="Current"
            className="w-full h-full object-cover rounded-lg"
          />
        </div>

        <div className="w-1/4 h-56 opacity-70">
          <img
            src={images[getNextIndex()]}
            alt="Next"
            className="w-full h-full object-cover rounded-lg"
          />
        </div>
      </div>

      <button
        onClick={nextSlide}
        className="absolute right-0 z-10 text-white bg-gray-800 px-4 py-2"
      >
        Next
      </button>
    </div>
  );
};

export default ImageCarousel;
