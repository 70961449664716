import styles from "../../Utility/styles";

const ImageWrapper = ({ children, modal }) => (
  <div
    id="caroselContainer"
    className="update-home-flex-unit"
    style={styles.caroselContainer(modal)}
  >
    {children}
  </div>
);

export default ImageWrapper
