import constants from "./constants";

const {
  ADD_HOME_IMAGE,
  SET_HOME_BANNER,
  SET_HOME_IMAGES,
  DELETE_HOME_IMAGE,
  SET_HOME_CONTENT,

  SET_ABOUT_BANNER,
  DELETE_ABOUT_IMAGE,

  SET_RESOURCES_BANNER,
  SET_RESOURCES_CONTENT,
  SET_RESOURCES_IMAGES,
  DELETE_RESOURCES_IMAGE,
  ADD_RESOURCES_IMAGE,
  ADD_RESOURCES_FILE,
  DELETE_RESOURCES_FILE,
  SET_RESOURCE_FILES,

  SET_VOTER_CONTENT,
  SET_VOTER_FILES,
  SET_VOTER_BANNER,
  DELETE_VOTER_FILE,

  SET_BLOG_FILES,
  SET_BLOG_BANNER,
  SET_BLOG_CONTENT,
  DELETE_BLOG_FILE,

  SET_CONTACT_BANNER,
  SET_CONTACT_CONTENT,
  DELETE_CONTACT_FILE,

  SET_GETINVOLVED_BANNER,
  ADD_GETINVOLVED_IMAGE,
  DELETE_GETINVOLVED_IMAGE,
  SET_GETINVOLVED_IMAGES,
  SET_GETINVOLVED_CONTENT,

  SET_PARTNERS_BANNER,
  SET_PARTNERS_CONTENT,
  SET_PARTNERS_FILES,
  ADD_PARTNERS_FILE,
  DELETE_PARTNERS_FILE
} = constants;

const actionTypeMap = {
  setContent: {
    home: SET_HOME_CONTENT,
    resources: SET_RESOURCES_CONTENT,
    voter: SET_VOTER_CONTENT,
    blog: SET_BLOG_CONTENT,
    contact: SET_CONTACT_CONTENT,
    getInvolved: SET_GETINVOLVED_CONTENT,
    partners: SET_PARTNERS_CONTENT
  },
  deleteImage: {
    home: DELETE_HOME_IMAGE,
    about: DELETE_ABOUT_IMAGE,
    resources: DELETE_RESOURCES_IMAGE,
    voter: DELETE_VOTER_FILE,
    blog: DELETE_BLOG_FILE,
    contact: DELETE_CONTACT_FILE,
    getInvolved: DELETE_GETINVOLVED_IMAGE,
    partners: DELETE_PARTNERS_FILE
  },
  addImage: {
    home: ADD_HOME_IMAGE,
    resources: ADD_RESOURCES_IMAGE,
    getInvolved: ADD_GETINVOLVED_IMAGE,
  },
  addFile: {
    resources: ADD_RESOURCES_FILE,
    partners: ADD_PARTNERS_FILE
  },
  setFiles: {
    resources: SET_RESOURCE_FILES,
    voter: SET_VOTER_FILES,
    blog: SET_BLOG_FILES,
    links: SET_RESOURCE_FILES,
    partners: SET_PARTNERS_FILES
  },
  setImages: {
    home: SET_HOME_IMAGES,
    resources: SET_RESOURCES_IMAGES,
    getInvolved: SET_GETINVOLVED_IMAGES
  },
  setBanner: {
    home: SET_HOME_BANNER,
    about: SET_ABOUT_BANNER,
    resources: SET_RESOURCES_BANNER,
    voter: SET_VOTER_BANNER,
    blog: SET_BLOG_BANNER,
    contact: SET_CONTACT_BANNER,
    getInvolved: SET_GETINVOLVED_BANNER,
    partners: SET_PARTNERS_BANNER
  },
};

const actionTypes = {
  setContent: (page, payload) => {
    const type = actionTypeMap.setContent[page];
    return type ? { type, payload } : null;
  },
  deleteFile: (page, payload, type) => {
    const action =
      type === "image" ? actionTypes.deleteImage : actionTypes._deleteFile;
    return action(page, payload);
  },
  _deleteFile: (page, payload) => {
    return page === "resources" || page === "links"
      ? { type: DELETE_RESOURCES_FILE, payload }
      : page === "voter"
      ? { type: DELETE_VOTER_FILE, payload }
      : page === "blog"
      ? { type: DELETE_BLOG_FILE, payload }
      : page === "contact"
      ? { type: DELETE_CONTACT_FILE, payload }
      : page === "partners"
      ? { type: DELETE_PARTNERS_FILE, payload }
      : null;
  },
  deleteImage: (page, payload) => {
    const type = actionTypeMap.deleteImage[page];
    return type ? { type, payload } : null;
  },
  addImage: (page, payload) => {
    const type = actionTypeMap.addImage[page];
    return type ? { type, payload } : null;
  },
  addFile: (page, payload) => {
    const type = actionTypeMap.addFile[page];
    return type ? { type, payload } : null;
  },
  setFiles: (page, payload) => {
    const type = actionTypeMap.setFiles[page];
    return type ? { type, payload } : null;
  },
  setImages: (page, payload) => {
    const type = actionTypeMap.setImages[page];
    return type ? { type, payload } : null;
  },
  setBanner: (page, payload) => {
    const type = actionTypeMap.setBanner[page];
    return type ? { type, payload } : null;
  },
};

export default actionTypes;
