import constants from "../Actions/constants";
const { SET_ABOUT_BANNER } = constants;

const aboutReducer = (state, action) => {
  switch (action?.type) {
    case SET_ABOUT_BANNER:
      return { ...state, banner: action?.payload };
    default:
      return state;
  }
};

export default aboutReducer;
