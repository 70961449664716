import React from "react";
import { useStore } from "../../Store/Provider/Connect";
import { generateKey } from "../../Utility/helpers";
import Banner from "../Presentational/Banner";
import Blurp from "../Presentational/Blurp";
import ResourceCard from "../Presentational/Cards/ResourceCard";
import Loading from "../Presentational/Loading";

function Resources() {
  const { getState } = useStore();
  const {
    resources: {
      banner: { src },
      content: { blurp },
      files,
    },
  } = getState();

  const pdfFiles = files.filter(({ _type }) => _type === "pdf");
  const externalLinks = files.filter(({ _type }) => _type === "link");

  return (
    <div>
      <Banner bgimg={src} text={"Resources"} />
      <Blurp text={blurp} />

      <div id="resource-card-container" className="flex flex-col items-center">
        <div className="grid xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-1 auto-rows-auto gap-4 w-full mt-4 justify-items-center">
          {pdfFiles.length ? (
            pdfFiles.map(
              ({ _name, image: { src }, file: { src: _src } }, i) => (
                <ResourceCard
                  key={generateKey("pdf-card", i)}
                  pdfbgImg={src}
                  pdfbuttontext={_name}
                  link={_src}
                />
              )
            )
          ) : (
            <Loading />
          )}
        </div>

        <div className="grid xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-1 auto-rows-auto gap-4 w-full mt-4 justify-items-center">
          {externalLinks.length ? (
            externalLinks
              .slice(0, 4)
              .map(({ _name, image: { src }, url, title }, i) => (
                <ResourceCard
                  key={generateKey("external-card", i)}
                  linkbgImg={src}
                  text={title}
                  link={url}
                  linkbuttontext={_name}
                />
              ))
          ) : (
            <Loading />
          )}
        </div>

        <div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-1 auto-rows-auto gap-4 w-full mt-4 justify-items-center">
          {externalLinks.length ? (
            externalLinks
              .slice(4)
              .map(({ _name, image: { src }, url, title }, i) => (
                <ResourceCard
                  key={generateKey("external-card", i + 4)}
                  linkbgImg={src}
                  text={title}
                  link={url}
                  linkbuttontext={_name}
                />
              ))
          ) : (
            <Loading />
          )}
        </div>
      </div>
    </div>
  );
}

export default Resources;
