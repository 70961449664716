import constants from "../Actions/constants";
const {
  SET_VOTER_FILES,
  SET_VOTER_BANNER,
  SET_VOTER_CONTENT,
  DELETE_VOTER_FILE,
} = constants;

const voterReducer = (state, action) => {
  switch (action?.type) {
    case SET_VOTER_FILES:
      return { ...state, files: action?.payload };
    case SET_VOTER_BANNER:
      return { ...state, banner: action?.payload };
    case SET_VOTER_CONTENT:
      return { ...state, content: action?.payload };
    case DELETE_VOTER_FILE:
      return {
        ...state,
        files: [...state.files].filter(({ _id }) => _id !== action?.payload),
      };
    default:
      return state;
  }
};

export default voterReducer;
