import { useStore } from "../../Store/Provider/Connect";
import React, { useState } from "react";
import styles from "../../Utility/styles";
import { getData, setFiles, setContent } from "../../Utility/helpers";
import actions from "../../Store/Actions/action.types";
import Requests from "../../Utility/Requests";
import logo from '../../Assets/CCCLogo.png'

function AdminSignIn({ setSignIn, handleAdminToken }) {
  const { dispatcher } = useStore();
  const [input, setInput] = useState(null);
  const [errors, setErrors] = useState({
    admin: false,
    password: false,
  });

  const handleInput = (e) =>
    setInput((prev) => ({ ...prev, [e.target.name]: e.target.value.trim() }));

  const handleSignIn = async (e) => {
    e.preventDefault();

    try {
      const response = await Requests.adminSignIn(input);
      const { data, status } = response;
      if (status === 201) {

        const { images = [], content = [], files } = await getData();

        if (images.length) {
          setFiles(images, [], dispatcher, actions);
        }

        if (files.length) {
          setFiles([], files, dispatcher, actions);
        }

        if (content.length) {
          setContent(content, dispatcher, actions);
        }

        handleAdminToken(data);
        setSignIn(false);
      } else {
        const errors = { admin: false, password: true };

        if (data === "admin") {
          errors.admin = true;
        }
        setErrors(errors);
      }
    } catch (err) {
      console.error("Error during sign-in:", err);
    }
  };

  return (
    <div className="american-flag" style={styles.adminSignInContainer(logo)}>
      <form
        onSubmit={handleSignIn}
        id="admin-signin-form-container"
        style={styles.adminSignInForm()}
      >
        <input
          style={styles.signInInput()}
          type="text"
          placeholder="Name"
          name="admin"
          onInput={handleInput}
        />
        {errors.admin && <p style={{ color: "red" }}>Admin Doesn't Exist</p>}
        <input
          style={styles.signInInput()}
          type="password"
          placeholder="Password"
          name="password"
          onInput={handleInput}
        />
        {errors.password && <p style={{ color: "red" }}>Wrong Password</p>}

        <button style={{ height: "7%", width: "15%" }}>Sign In</button>
      </form>
    </div>
  );
}

export default AdminSignIn;
