import React, { useState } from "react";
import { NavHashLink } from "react-router-hash-link";
import { useLocation } from "react-router-dom";
import { FiMenu, FiX } from "react-icons/fi";
import Logo from "../../Assets/CCCLogo.png";

const links = [
  { to: "/", title: "Home" },
  { to: "/about", title: "About" },
  { to: "/resources", title: "Resources" },
  { to: "/voter", title: "Voter Resources" },
  { to: "/events", title: "Get Involved" },
  { to: "/articles", title: "Articles" },
  { to: "/partners", title: "Partners" },
  { to: "/contact", title: "Contact" },
];

const NavLinks = ({ links, textColor, handleLinkClick }) => {
  const location = useLocation();
  return (
    <>
      {links.map((link) => {
        const isActive = location.pathname === link.to;

        return (
          <NavHashLink
            key={link.to}
            to={link.to}
            className={`xl:text-xl lg:text-md md:text-md font-medium transition-colors duration-300 hover:text-accent xl:px-4 lg:px-2 md:px-1 ${
              isActive ? "text-primaryRed underline" : textColor
            }`}
            onClick={handleLinkClick}
          >
            {link.title}
          </NavHashLink>
        );
      })}
    </>
  );
};

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  const handleToggleMenu = () => {
    setToggleMenu((prev) => !prev);
  };

  const handleLinkClick = () => {
    setToggleMenu(false);
  };

  const handleCloseMenu = () => {
    setToggleMenu(false);
  };

  return (
    <nav className="flex flex-row justify-between items-center bg-white shadow-md py-1 px-4">
      <div className="flex items-center">
        <img
          src={Logo}
          alt="CCC Logo"
          className="xl:h-16 lg:h-16 md:h-16 sm:h-10 xs:h-10"
        />
        <div className="flex px-1">
          <div className="text-gray-500 xl:text-lg lg:text-md md:text-md sm:text-sm xs:text-sm">
            Cochise County
          </div>
          <div className="px-1 text-gray-500 xl:text-lg lg:text-md md:text-md sm:text-sm xs:text-sm">
            Conservatives
          </div>
        </div>
      </div>

      <div className="md:hidden">
        <button
          onClick={handleToggleMenu}
          className="text-gray-700 focus:outline-none"
        >
          {toggleMenu ? <FiX size={30} /> : <FiMenu size={30} />}
        </button>
      </div>
      <div className="hidden md:flex items-center gap-4">
        <NavLinks
          links={links}
          textColor="text-gray-700"
          handleLinkClick={handleLinkClick}
        />
      </div>
      {toggleMenu && (
        <div
          className="fixed inset-0 z-50 bg-gray-800 bg-opacity-90 flex flex-col items-center justify-center"
          onClick={handleCloseMenu}
        >
          <div
            className="flex flex-col items-center gap-6"
            onClick={(e) => e.stopPropagation()}
          >
            <NavLinks
              links={links}
              textColor="text-white"
              handleLinkClick={handleLinkClick}
            />
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
