import FileInput from "./FileInput";

const UploadFile = ({
  form,
  onSubmit,
  onChange,
  image,
  type,
  name,
  styles,
  setIsUploaded,
  uploadType,
  isUploaded,
}) => {
  return (
    <>
      {form && (
        <div
          className="updateField-flex-unit"
          style={{ ...styles.updateFieldUnit(), width: "80%" }}
        >
          <form onSubmit={onSubmit} style={styles.updateFieldForm(type)}>
            {image && (
              <img
                src={image.src}
                style={{
                  ...styles.bannerContainer(),
                  boxShadow: "0px 5px 7px black",
                }}
                alt={image.src}
              />
            )}

            {type === "file" ? (
              <FileInput
                onFileChange={(files) => {
                  setIsUploaded((prev) => ({ ...prev, [uploadType]: true }));
                  onChange({ target: { files } });
                }}
                buttonText="Choose File"
              />
            ) : (
              <input
                style={styles.updateFieldUnitInput(image)}
                type={type}
                name={name}
                onChange={(e) => {
                  setIsUploaded((prev) => ({ ...prev, [uploadType]: true }));
                  onChange(e);
                }}
                placeholder={
                  type === "text" || type === "textarea"
                    ? "...enter content here"
                    : null
                }
              />
            )}

            <button
              disabled={!isUploaded}
              style={styles.updateFieldUpload(isUploaded)}
            >
              Update
            </button>
          </form>
        </div>
      )}
    </>
  );
};

export default UploadFile;
