import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import Connect from "./Store/Provider/Connect";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Connect>
      <App />
    </Connect>
  </React.StrictMode>
);
