import constants from "../Actions/constants";

const {
  SET_PARTNERS_BANNER,
  SET_PARTNERS_CONTENT,
  SET_PARTNERS_FILES,
  ADD_PARTNERS_FILE,
  DELETE_PARTNERS_FILE,
} = constants;

const partnersReducer = (state, action) => {
  switch (action?.type) {
    case SET_PARTNERS_BANNER:
      return { ...state, banner: action?.payload };
    case SET_PARTNERS_CONTENT:
      return { ...state, content: action?.payload };
    case SET_PARTNERS_FILES:
      return { ...state, files: action?.payload };
    case ADD_PARTNERS_FILE:
      return { ...state, files: [...state.files, action?.payload] };
    case DELETE_PARTNERS_FILE:
      return {
        ...state,
        files: [...state.files].filter(({ _id }) => _id !== action?.payload),
      };
    default:
      return state;
  }
};

export default partnersReducer;
