import React from "react";
import Rumble from "../../Assets/icons8-rumble-48.png";
import Facebook from "../../Assets/fbicon.png";
import Gmail from "../../Assets/gmailicon.png";

const Footer = () => {
  return (
    <footer className="flex justify-between items-center w-full h-20 bg-secondaryBlue text-white px-8">
      <div className="flex flex-col justify-center">
        <span className="ml-2 mb-2">Follow Us</span>
        <div className="flex space-x-3 ml-1">
          <a
            href="https://rumble.com/user/CochiseCountyConservatives"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Rumble} alt="Rumble icon" className="h-10 w-10" />
          </a>
          <a
            href="https://www.facebook.com/groups/1130065360779113"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Facebook} alt="Facebook icon" className="h-10 w-10" />
          </a>
        </div>
      </div>
      <div className="hidden sm:flex flex-col items-center">
        <p>All Rights Reserved ©</p>
        <p>Cochise County Conservatives</p>
      </div>
      <div className="flex flex-col justify-center items-end">
        <span className="mb-2">Contact Us</span>
        <div>
          <a href="mailto:CochiseConservatives@gmail.com">
            <img src={Gmail} alt="Gmail icon" className="h-10 w-9" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
