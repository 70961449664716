import styles from "../../Utility/styles";
import { generateKey } from "../../Utility/helpers";
import { NavLink } from "react-router-dom";

const AdminNavBar = ({
  handleSignOut = (f) => f,
  getLinks = (f) => f,
  admin = false,
}) => (
  <>
    <nav style={styles.adminNavContainer()}>
      {getLinks().map(({ to, value }, i) => (
        <div style={styles.adminNavUnit()} key={generateKey("main-unit", i)}>
          <NavLink style={({ isActive }) => styles.navLink(isActive)} to={to}>
            {value}
          </NavLink>
        </div>
      ))}
      
      {admin && (
        <div
          style={{
            ...styles.adminNavUnit(),
            color: "red",
            fontWeight: "bolder",
            textShadow: "0px 1px 2px black",
          }}
          onClick={handleSignOut}
        >
          <i
            className="fas fa-sign-out-alt"
            style={{ color: "red", fontSize: "x-large" }}
          />
        </div>
      )}
    </nav>
  </>
);

export default AdminNavBar;
