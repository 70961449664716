import styles from "../../Utility/styles";

const CarouselDisplay = ({ src, deleteCaroselItem, id, type, _type }) => (
  <div style={styles.imageCardContainer()}>
    <div style={styles.caroselDisplayContainer()}>
      <img src={src} alt={src} style={styles.imageElement()} />
    </div>

    <div style={styles.deleteContainer()}>
      <div
        style={{
          ...styles.deleteButton(),
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "x-large",
        }}
      >
        <i
          className="far fa-trash-alt"
          onClick={() => deleteCaroselItem(id, type)}
        />
      </div>
    </div>
  </div>
);

export default CarouselDisplay;
