const Blurp = ({ text }) => {
  return (
    <div className="px-5 py-8 my-2">
      <p className="text-base xs:text-md sm:text-md md:text-xl lg:text-2xl xl:text-2xl text-primaryRed leading-[35px] font-normal not-italic text-center">
        {text}
      </p>
    </div>
  );
};

export default Blurp;
