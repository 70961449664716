import { useStore } from "../../Store/Provider/Connect";
import Banner from "../Presentational/Banner";
import ContactForm from "../Presentational/ContactForm";

function Contact() {
  const { getState } = useStore(),
    {
      contact: {
        banner: { src },
      },
    } = getState();

  return (
    <div>
      <Banner text={"Contact Us"} bgimg={src} />
      <ContactForm />
    </div>
  );
}

export default Contact;
