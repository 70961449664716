import React from "react";

const Banner = ({ text, bgimg }) => {
  return (
    <div className="relative w-full xl:h-96 lg:h-96 md:h-72 sm:h-48 xs:h-48 shadow-lg overflow-hidden">
      <img
        src={bgimg}
        alt="Banner background"
        className="w-full h-full object-cover"
      />
      <div className="absolute inset-0 flex items-start justify-end xl:pr-16 lg:pr-16 md:pr-10 sm:pr-5 xs:pr-5">
        <h1 className="text-white xl:text-5xl md:text-3xl sm:text-lg mt-10 font-medium text-shadow-lg">
          {text}
        </h1>
      </div>
    </div>
  );
};

export default Banner;
