import Requests from "./Requests";

export const generateKey = (str, n) =>
  `${str}-${Math.floor(Math.random() * 9999 * (n + 1))}`;

export const getTitle = (fn, page) => {
  return `${fn(page).replace(/^\D|\s\D/g, (l) => l.toUpperCase())}`;
};

export const resetState = ({
  setIsUploaded,
  setInput,
  setModal,
  setModalImage,
  setModalFile,
  set_type,
}) => {
  setIsUploaded({
    banner: false,
    image: false,
    content: false,
    file: false,
    modalField: false,
  });
  setInput({});
  setModal(false);
  setModalImage([]);
  setModalFile(null);
  set_type("");
};

export const getAdminLinks = () => [
  { to: "/admin/home", value: "Home Page" },
  { to: "/admin/about", value: "About Page" },
  { to: "/admin/files", value: "Resource Files" },
  { to: "/admin/links", value: "Resource Links" },
  { to: "/admin/voter", value: "Voter Page" },
  { to: "/admin/blog", value: "Articles Page" },
  { to: "/admin/events", value: "Get Involved" },
  { to: "/admin/contact", value: "Contact" },
  { to: "/admin/partners", value: "Partners" },
];

export const getPageLinks = () => [
  { to: "/", value: "Home Page" },
  { to: "/about", value: "About Page" },
  { to: "/resources", value: "Resources Page" },
  { to: "/voter", value: "Voter Page" },
  { to: "/blog", value: "Articles Page" },
  { to: "/contact", value: "Contact Page" },
];

export const upload_file = async (e, formData, type, token) => {
  e.preventDefault();

  try {
    const {
      data: { fileID, imageID, infoID },
    } = await Requests.upload_info(formData, type);

    formData.append("fileID", fileID);
    formData.append("imageID", imageID);
    formData.append("infoID", infoID);

    const {
      data: { file, image },
    } = await Requests.upload_files(formData, type, token);

    const {
      data: { imageUpdate: final, pageFiles: files },
      status,
    } = await Requests.update_info({ file, image }, type);

    return { final, files, status };
  } catch (err) {
    console.error("Error during file upload:", err);
  }
};

export const uploadFile = async (e, formData, location, type, token) => {
  e.preventDefault();

  try {
    const initialInfo = {
      src: "",
      name: "",
      type,
      location,
    };

    const { data: { _id } = {} } = await Requests.uploadInfo(
      initialInfo,
      type,
      token
    );

    if (!_id) {
      window.alert("Something went wrong. Please contact Nate.");
      return;
    }

    formData.append("id", _id);
    formData.append("type", type);

    const { data } = await Requests.uploadFile(formData, type, token);

    const updatedInfo = {
      ...data,
      location,
      name: "",
      type,
    };
    const { data: final, status } = await Requests.updateInfo(
      updatedInfo,
      token
    );

    deleteFormKeys(formData);

    return { final, status };
  } catch (err) {
    console.error("Error during file upload:", err);
  }
};

export const dispatchFile = (dispatcher, actions, type, page, final) => {
  switch (type) {
    case "file":
      dispatcher(actions.addFile(page, final));
      break;
    case "image":
      dispatcher(actions.addImage(page, final));
      break;
    default:
      return null;
  }
};

export const getData = async () => {
  try {
    const [imagesResponse, contentResponse, filesResponse] = await Promise.all([
      Requests.getImages(),
      Requests.getContent(),
      Requests.getFiles(),
    ]);

    return {
      images: imagesResponse.data || [],
      content: contentResponse.data || [],
      files: filesResponse.data || [],
    };
  } catch (error) {
    console.error("Error fetching data:", error);
    return { images: [], content: [], files: [] };
  }
};

export const setFiles = (images, files, dispatcher, actions) => {
  const locations = {
    home: "banner-home",
    about: "banner-about",
    resources: "banner-resources",
    voter: "banner-voter",
    blog: "banner-blog",
    contact: "banner-contact",
    getInvolved: "banner-getInvolved",
    partners: "banner-partners",
  };

  Object.keys(locations).forEach((key) => {
    const bannerImage = images.find(
      (image) => image.location === locations[key]
    );
    if (bannerImage) {
      dispatcher(actions.setBanner(key, bannerImage));
    }
  });

  const resourceFiles = files.filter(
      (file) => file._type === "pdf" || file._type === "link"
    ),
    voterFiles = files.filter((file) => file._type === "rep"),
    homeImages = images.filter((image) => image.location === "home"),
    blogFiles = files.filter((file) => file._type === "blog"),
    getInvolvedImages = images.filter(
      (image) => image.location === "getInvolved"
    ),
    partnersFiles = files.filter((file) => file._type === "partners");


  if (homeImages.length) {
    dispatcher(actions.setImages("home", homeImages));
  }
  if (resourceFiles.length) {
    dispatcher(actions.setFiles("resources", resourceFiles));
  }
  if (voterFiles.length) {
    dispatcher(actions.setFiles("voter", voterFiles));
  }
  if (blogFiles.length) {
    dispatcher(actions.setFiles("blog", blogFiles));
  }
  if (getInvolvedImages.length) {
    dispatcher(actions.setImages("getInvolved", getInvolvedImages));
  }
  if (partnersFiles.length) {
    dispatcher(actions.setFiles("partners", partnersFiles));
  }
};

export const setContent = (content, dispatcher, actions) => {
  const aboutContent = content.find((data) => data.location === "about"),
    resourcesContent = content.find((data) => data.location === "resources"),
    voterContent = content.find((data) => data.location === "voter"),
    blogContent = content.find((data) => data.location === "blog"),
    contactContent = content.find((data) => data.location === "contact"),
    homeContent = content.find((data) => data.location === "home"),
    getInvolvedContent = content.find(
      (data) => data.location === "getInvolved"
    ),
    partnersContent = content.find((data) => data.location === "partners");

  if (aboutContent) {
    dispatcher(actions.setContent("about", aboutContent));
  }
  if (resourcesContent) {
    dispatcher(actions.setContent("resources", resourcesContent));
  }
  if (voterContent) {
    dispatcher(actions.setContent("voter", voterContent));
  }
  if (blogContent) {
    dispatcher(actions.setContent("blog", blogContent));
  }
  if (contactContent) {
    dispatcher(actions.setContent("contact", contactContent));
  }
  if (homeContent) {
    dispatcher(actions.setContent("home", homeContent));
  }
  if (getInvolvedContent) {
    dispatcher(actions.setContent("getInvolved", getInvolvedContent));
  }
  if (partnersContent) {
    dispatcher(actions.setContent("partners", partnersContent));
  }
};

export const deleteFormKeys = (file) => {
  [
    "image",
    "_name",
    "file",
    "location",
    "title",
    "email",
    "id",
    "fileID",
    "imageID",
    "infoID",
    "url",
    "blog",
    "author",
    "phone",
  ].forEach((key) => file.delete(key));
};
