import styles from "../../Utility/styles";
import UploadFile from "./UploadFile";

const UpdateField = ({
  title,
  onSubmit = (f) => f,
  form = false,
  onChange = (f) => f,
  type,
  setIsUploaded = (f) => f,
  isUploaded,
  uploadType,
  hasData,
  content,
  name,
  image = null,
}) => (
  <div style={styles.updateFieldContainer()}>
    <div className="updateField-flex-unit" style={styles.updateFieldUnit()}>
      <h3
        style={{ ...styles.updateFieldTitle(), ...styles.fontTextOne(hasData) }}
        title={content ? content.toString() : null}
      >
        {title}
      </h3>
    </div>

    {form && (
      <UploadFile
        form={form}
        onSubmit={onSubmit}
        onChange={onChange}
        image={image}
        type={type}
        name={name}
        styles={styles}
        setIsUploaded={setIsUploaded}
        uploadType={uploadType}
        isUploaded={isUploaded}
      />
    )}
  </div>
);

export default UpdateField;
