import { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import Blurp from "./Blurp";

const ContactForm = () => {
  const form = useRef();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [showMessageSentAlert, setShowMessageSentAlert] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_5gsnz3b", "template_kryh3vc", form.current, {
        publicKey: "BJWN9qMcB5Qdys0zi",
      })
      .then(
        () => {
          console.log("SUCCESS!");
          form.current.reset();
          setName("");
          setEmail("");
          setMessage("");
          setShowMessageSentAlert(true);
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };

  return (
    <>
      <Blurp text="Your voice matters to us. Whether you have questions, suggestions, or simply want to share your thoughts, we’re here to listen. Reach out to the Cochise County Conservatives to learn more about our mission, events, and how you can get involved. Together, we can make a difference in our community and beyond. Contact us today and let’s work together for a better future." />
      <div className="flex flex-col justify-center items-center">
        <form
          ref={form}
          onSubmit={sendEmail}
          className="space-y-4 xl:w-1/2 lg:w-1/2 md:w-2/3 sm:w-2/3 xs:w-2/3 my-4"
        >
          {showMessageSentAlert && (
            <div className="flex justify-center text-grey-500 xl:text-md lg:text-md md:text-sm sm:text-xs xs:text-xs">
              Your message has been sent successfully!
            </div>
          )}
          <div className="flex flex-col">
            <input
              id="name"
              name="name"
              placeholder="Name"
              type="text"
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div className="flex flex-col">
            <input
              id="email"
              name="email"
              type="email"
              placeholder="Email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="flex flex-col">
            <textarea
              id="message"
              name="message"
              placeholder="Message"
              rows={4}
              required
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>

          <button
            type="submit"
            className="w-full bg-primaryRed text-white rounded-md shadow-md py-2 mt-4 hover:bg-red-700"
          >
            Send
          </button>
        </form>
      </div>
    </>
  );
};

export default ContactForm;
