import constants from "../Actions/constants";
const { SET_BLOG_FILES, SET_BLOG_BANNER, SET_BLOG_CONTENT, DELETE_BLOG_FILE } =
  constants;

const blogReducer = (state, action) => {
  switch (action?.type) {
    case SET_BLOG_FILES:
      return { ...state, files: action?.payload };
    case SET_BLOG_BANNER:
      return { ...state, banner: action?.payload };
    case SET_BLOG_CONTENT:
      return { ...state, content: action?.payload };
    case DELETE_BLOG_FILE:
      return {
        ...state,
        files: [...state.files].filter(({ _id }) => _id !== action?.payload),
      };
    default:
      return state;
  }
};

export default blogReducer;
