import React, { useRef } from 'react';
import styles from '../../Utility/styles';

const CustomFileInput = ({ onFileChange, buttonText = 'Choose File' }) => {
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const files = event.target.files;
    if (onFileChange) {
      onFileChange(files);
    }
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div style={{marginLeft: "5%"}}>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: 'none'}}
      />
      <button type="button" onClick={handleButtonClick} style={{...styles.updateFieldUpload(), width: "119%", height: "37px", fontFamily: "helvetica"}}>
        {buttonText}
      </button>
    </div>
  );
};

export default CustomFileInput;