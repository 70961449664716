import { useStore } from "../../Store/Provider/Connect";
import Banner from "../Presentational/Banner";
import Title from "../Presentational/Title";
import Blurp from "../Presentational/Blurp";
import ImageCarousel from "../Presentational/ImageCarousel";

function Home() {
  const { getState } = useStore(),
    {
      home: {
        banner: { src },
        content: { blurp },
        images,
      },
    } = getState();

  return (
    <div>
      <Banner bgimg={src} text={"Cochise County Conservatives"} />
      <Title text={"Our Mission"} />
      <Blurp text={blurp} />
      <ImageCarousel images={images.map(({ src }) => src)} />
    </div>
  );
}

export default Home;
